import EmbedProjectImage, {
  useEmbedProjectContainerResize,
} from 'shared/src/components/EmbedProjectImage';
import { WhiteLabelSettings } from 'shared/src/features/whitelabel/whitelabel.types';
import { generatePublishedUrl } from 'shared/src/utils/generatePublishedUrl';
import { useRouter } from 'next/router';
import { Routes } from 'shared/src/features/router/router.constants';
import { useMemo } from 'react';
import { LANDING_URL } from 'shared/src/features/ui/ui.constants';
import getFinalRendererDimensions from 'shared/src/utils/getFinalRendererDimensions';
import {
  DemoPageEmbedSearchParamValue,
  DemoPageSearchParams,
} from 'shared/src/features/demoPageSearchParams/demoPageSearchParams.constants';

import { useIsLoggedIn } from 'utils/isLoggedIn';
import { DEFAULT_WHITE_LABEL_CONFIG } from './SharePageImage.constants';
import ShareWhitelabelLink from 'components/ShareWhitelabelLink';
import styles from './SharePageImage.module.css';
import { getSearchParams } from 'utils/getSearchParams';
import { GetPublishedProjectInfoResponse } from '../api/projectInfo.api';
import ShareImageLandscapeLayout from 'components/ShareImageLandscapeLayout';
import ShareImagePortraitLayout from 'components/ShareImagePortraitLayout';

interface SharePageImageProps {
  projectId: string;
  projectInfo: GetPublishedProjectInfoResponse['project'];
  whitelabelSettings: WhiteLabelSettings;
}

function SharePageImage({
  projectId,
  projectInfo,
  whitelabelSettings,
}: SharePageImageProps): JSX.Element {
  const router = useRouter();

  const search = getSearchParams(router.asPath);
  const isOfflinePage = router.pathname.startsWith('/offline/');
  search.set(DemoPageSearchParams.Embed, DemoPageEmbedSearchParamValue.Inline);

  const isLoggedIn = useIsLoggedIn();

  const [demoContainerRef, rendererContainerStyle] =
    useEmbedProjectContainerResize<HTMLDivElement>({
      data: {
        display_config: projectInfo.display_config,
        dimensions: projectInfo.dimensions,
        isResponsive: projectInfo.feature_flags.responsive_image_demo_player,
      },
    });

  const title = projectInfo.display_config.renderer_title || projectInfo.name;

  const isDefaultText =
    whitelabelSettings.header_link_text === DEFAULT_WHITE_LABEL_CONFIG.TEXT &&
    whitelabelSettings.header_link_url === DEFAULT_WHITE_LABEL_CONFIG.LINK;

  const showLinkElement = Boolean(
    whitelabelSettings.header_link_url && whitelabelSettings.header_link_text
  );

  const linkElement = useMemo(() => {
    if (!showLinkElement) return null;

    let linkHref = whitelabelSettings.header_link_url || Routes.SignUp;
    let tooltipContent = null;

    if (isLoggedIn && isDefaultText) {
      linkHref = Routes.TeamSettings;
      tooltipContent = <span>Click to customize the text</span>;
    }

    return (
      <ShareWhitelabelLink
        key="whitelabel-link"
        text={whitelabelSettings.header_link_text as string}
        href={linkHref}
        tooltipContent={tooltipContent}
      />
    );
  }, [showLinkElement, isLoggedIn, isDefaultText, whitelabelSettings]);

  const hrefUrl = useMemo(() => {
    if (isLoggedIn && whitelabelSettings.logo_href) {
      return whitelabelSettings.logo_href;
    } else if (isLoggedIn && !whitelabelSettings.logo_href) {
      return `${process.env.NEXT_PUBLIC_DASHBOARD_URL}${Routes.TeamSettings}`;
    } else if (!isLoggedIn && !whitelabelSettings.logo_href) {
      return LANDING_URL;
    } else {
      return whitelabelSettings?.logo_href || LANDING_URL;
    }
  }, [isLoggedIn, whitelabelSettings]);

  const finalRendererDimensinos = getFinalRendererDimensions(
    projectInfo.display_config,
    projectInfo.dimensions
  );
  const isPortraitDemo =
    finalRendererDimensinos.height > finalRendererDimensinos.width;
  const LayoutComponent = isPortraitDemo
    ? ShareImagePortraitLayout
    : ShareImageLandscapeLayout;

  return (
    <LayoutComponent
      logoSrc={whitelabelSettings.logo}
      title={title}
      hrefUrl={hrefUrl}
      linkElement={linkElement}
      showFooterLinkElement={showLinkElement}
      disabledAuthenticatedTooltip={!isLoggedIn}
    >
      <div ref={demoContainerRef} className={styles.embedDemoContainerInner}>
        <div className={styles.embedDemoWrapper} style={rendererContainerStyle}>
          <EmbedProjectImage
            url={generatePublishedUrl({
              shareId: projectId,
              path: isOfflinePage ? 'demo/offline' : 'demo',
              baseUrl: process.env.NEXT_PUBLIC_HOST_URL || '',
              search: `?${search}`,
            })}
            imageDimensions={
              projectInfo.display_config.renderer_dimensions ??
              projectInfo.dimensions
            }
            imageFrame={projectInfo.display_config.renderer_border_color}
            testId="share-embed-demo"
            isResponsive={
              projectInfo.feature_flags.responsive_image_demo_player
            }
          />
        </div>
      </div>
    </LayoutComponent>
  );
}

export default SharePageImage;
